import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { DrowsyTubeCalendar } from "./DrowsyTubeCalendar";
import { useEffect, useState } from "react";
import {
  Form,
  Button,
  Card,
  ListGroup,
  Spinner,
  Navbar,
  Container,
  Alert,
  Nav,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  AddCircleOutline,
  CloseOutline,
  InformationCircleOutline,
  Link,
  LogoDiscord,
  LogoFacebook,
  LogoInstagram,
  LogoTwitter,
  Mail,
  TrashOutline,
} from "react-ionicons";
import { FetchData } from "./logic/youtube";

const CHANNEL_COLLECTION_KEY = "_DF_channelCollection";
const API_KEY_KEY = "DF_API_KEY";
const EVENTS_KEY = "__DF_EVENTS";

function App() {
  const [channelCollection, setChannelCollection] = useState(
    new Set(JSON.parse(localStorage.getItem(CHANNEL_COLLECTION_KEY)) || [])
  );
  const [channelID, setChannelID] = useState("");
  const [API_KEY, setAPI_KEY] = useState(
    localStorage.getItem(API_KEY_KEY) || ""
  );

  const [events, setEvents] = useState(
    JSON.parse(localStorage.getItem(EVENTS_KEY))?.map((ev) => ({
      ...ev,
      start: new Date(ev.start),
      end: new Date(ev.end),
    })) || []
  );

  const [processing, setProcessing] = useState(false);

  const [error, setError] = useState("");

  const [sawCookies, setSawCookies] = useState(false);

  useEffect(() => {
    localStorage.setItem(EVENTS_KEY, JSON.stringify(events));
  }, [events]);

  useEffect(() => {
    localStorage.setItem(API_KEY_KEY, API_KEY);
  }, [API_KEY]);

  useEffect(() => {
    setChannelID(""); // This is be executed when the state changes
    localStorage.setItem(
      CHANNEL_COLLECTION_KEY,
      JSON.stringify([...channelCollection])
    );
  }, [channelCollection]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Navbar bg="light" variant="light">
        <Container style={{ width: "65%" }}>
          <Navbar.Brand href="https://drowsyfox.com">
            <img
              style={{ maxHeight: "38px" }}
              src="//images.squarespace-cdn.com/content/v1/5f5a94c4db922d201a336e86/1599772342549-94A719DHZPO5ECARO0RG/logotype-512x100.png?format=1500w"
              alt="Drowsyfox"
            />
          </Navbar.Brand>
          <Nav className="ms-auto align-right">
            <Nav.Link
              class="icon icon--fill"
              href="https://www.instagram.com/drowsyfoxgames/"
            >
              <LogoInstagram />
            </Nav.Link>

            <Nav.Link
              class="icon icon--fill"
              href="https://discord.com/invite/MfXa7h3RRg"
            >
              <LogoDiscord />
            </Nav.Link>

            <Nav.Link
              class="icon icon--fill"
              href="https://www.facebook.com/drowsyfoxgames/"
            >
              <LogoFacebook />
            </Nav.Link>

            <Nav.Link
              class="icon icon--fill"
              href="https://twitter.com/drowsyfoxgames"
            >
              <LogoTwitter />
            </Nav.Link>

            <Nav.Link class="icon icon--fill" href="mailto:info@drowsyfox.com">
              <Mail />
            </Nav.Link>
            {/* <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link> */}
          </Nav>
        </Container>
      </Navbar>
      <main
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{ display: "flex", width: "70%", justifyContent: "center" }}
        >
          <DrowsyTubeCalendar events={events} />
          <Card style={{ width: "25%", padding: "5px" }}>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>API Key</Form.Label>
                <OverlayTrigger
                  delay={{ show: 100, hide: 500 }}
                  placement="right"
                  overlay={(props) => (
                    <Tooltip id="button-tooltip-2" {...props}>
                      <Nav.Link
                        style={{ color: "orange" }}
                        href={
                          "https://developers.google.com/youtube/v3/getting-started"
                        }
                      >
                        Learn how to get one here
                      </Nav.Link>
                    </Tooltip>
                  )}
                >
                  <span className="ms-1">
                    <InformationCircleOutline />
                  </span>
                </OverlayTrigger>
                <div
                  className={"gap-1"}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="ABC123"
                    value={API_KEY}
                    onChange={(v) => {
                      setAPI_KEY(v.target.value);
                    }}
                  />
                </div>
                <Form.Label>Channel ID</Form.Label>
                <div
                  className={"gap-1"}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="UCTUXpMlWwf0Ef__aYRQCS8w"
                    value={channelID}
                    onChange={(v) => {
                      setChannelID(v.target.value);
                    }}
                  />
                  <Button
                    variant={"outline-success"}
                    onClick={() => {
                      setChannelCollection(
                        new Set([...channelCollection, channelID])
                      );
                    }}
                  >
                    <AddCircleOutline color={"#4acfb6"}></AddCircleOutline>
                  </Button>
                </div>
                <div className="d-grid gap-2" style={{ marginTop: "5px" }}>
                  {processing ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <Button
                      active={!processing}
                      variant="primary"
                      onClick={async () => {
                        if (
                          channelCollection.length < 1 ||
                          API_KEY.length < 5
                        ) {
                          return;
                        }

                        setProcessing(true);
                        let eventData = await FetchData(
                          API_KEY,
                          channelCollection,
                          setError
                        );
                        if (eventData) {
                          setEvents(eventData);
                        }
                        setProcessing(false);
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </Form.Group>
            </Form>
            <ListGroup>
              {[...channelCollection].map((id) => (
                <ListGroup.Item
                  key={id}
                  className="d-flex justify-content-between"
                  style={{ width: "100%" }}
                >
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {id}
                  </span>
                  <Button
                    variant="outline-danger"
                    onClick={() => {
                      let newVal = [...channelCollection].filter(
                        (a) => a != id
                      );

                      setChannelCollection(new Set(newVal));
                    }}
                  >
                    <TrashOutline color="red" />
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <div className="d-grid gap-2" style={{ marginTop: "5px" }}>
              <Button
                variant="outline-danger"
                onClick={() => {
                  setChannelCollection([]);
                }}
              >
                CLEAR
              </Button>
            </div>
            <Card style={{ marginTop: "15px" }}>
              <Nav.Link href="https://youtu.be/udOzhl7RoYE">
                Video Tutorial: How to use - <b>Click Here</b>
              </Nav.Link>
            </Card>
          </Card>
        </div>
      </main>
      {!sawCookies && (
        <Container className={"fixed-bottom d-flex justify-content-between"}>
          <Alert variant={"info"}>
            By using this website, you agree to our use of cookies. We use
            cookies to provide you with a great experience and to help our
            website run effectively.
            <Button
              variant="link"
              onClick={() => {
                setSawCookies(true);
              }}
            >
              <CloseOutline />
            </Button>
          </Alert>
        </Container>
      )}
      {error && (
        <Card
          style={{
            width: "30%",
            position: "absolute",
            alignSelf: "center",
            backgroundColor: "",
            padding: "10px",
          }}
        >
          <h1>ERROR</h1>
          <p>{error}</p>
          <Button
            variant="warning"
            onClick={() => {
              setError("");
              setProcessing(false);
            }}
          >
            OK
          </Button>
        </Card>
      )}
    </div>
  );
}

export default App;
