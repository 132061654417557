import "react-big-calendar/lib/css/react-big-calendar.css";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

const localizer = momentLocalizer(moment);

export const DrowsyTubeCalendar = ({ events = [] }) => (
  <Calendar
    localizer={localizer}
    events={events}
    startAccessor="start"
    endAccessor="end"
    defaultView="week"
    allDayAccessor="allDay"
    views={["week"]}
    style={{ height: "90vh", width: "70%" }}
    drilldownView={null}
    toolbar={false}
    selectable={false}
    eventPropGetter={(event, start, end, isSelected) => {
      let backgroundColor = "#C6C7DA";
      if (event.score >= 8) {
        backgroundColor = "#26C6F8";
      } else if (event.score > 6) {
        backgroundColor = "#A3E8FD";
      } else if (event.score > 4) {
        backgroundColor = "#FAF2EF";
      } else if (event.score > 2) {
        backgroundColor = "#E3E0EC";
      }
      var style = {
        backgroundColor: backgroundColor,
        borderRadius: "0px",
        fontSize: ".7em",
        color: "black",
        border: "0px",
        maxHeight: "3em",
        display: "block",
        whiteSpace: "normal",
      };
      return {
        style: style,
      };
    }}
    components={{
      header: ({ date, label, localizer }) => {
        return <span>{date.toLocaleString("en-us", { weekday: "long" })}</span>;
      },
      timeGutterHeader: () => (
        <span
          style={{ width: "100%", height: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <img
            alt="drowsyfox logo symbol"
            style={{ width: "65%", maxHeight: "100%" }}
            src="https://images.squarespace-cdn.com/content/v1/5f5a94c4db922d201a336e86/1602652487167-P25A8BJAEAYPTC3MK9VE/drowsy-icon-circle%400.5x.png?format=500w"
          />
        </span>
      ),
    }}
  />
);
