// Dani, DevDuck
// const channelIDs = ["UCIabPXjvT5BVTxRDPCBBOOQ", "UCKCTmact-90hXpV2ns8GSsA"];

import moment from "moment";

async function getVidIdsByChannelID(API_KEY, channelID, data) {
  const response = await fetch(
    encodeURI(
      `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${channelID}&part=snippet,id&order=date&maxResults=10`
    )
  );

  if (!response.ok) {
    throw new Error(
      `An error has occured: ${response.status}\n\n${
        JSON.parse(await response.text())["error"]["message"]
      }`
    );
  }

  const vidsByChannelID = await response.json();

  const vids = await vidsByChannelID.items;

  data[channelID] = {
    videos: {},
    totalViewCount: 0,
    avgViewCount: -1,
    maxViewCount: 0,
    minViewCount: 999999999999,
  };

  vids.forEach((vid) => {
    if (vid.id.videoId != null) {
      data[channelID]["videos"][vid.id.videoId] = { ...vid.snippet };
    }
  });
  const ids = vids.map((vid) => vid.id.videoId).filter(Boolean);
  return ids;
}

async function updateViewsForVideos(API_KEY, videoIDs, data) {
  const ChannelIDs = Object.keys(data);

  let response = await fetch(
    encodeURI(`https://youtube.googleapis.com/youtube/v3/videos?part=contentDetails,statistics&id=${videoIDs.join(
      ","
    )}&key=${API_KEY}
    `)
  );

  if (!response.ok) {
    throw new Error(`An error has occured: ${response.status}\n${response}`);
  }

  const vidsStats = await response.json();

  vidsStats.items.forEach((item) => {
    ChannelIDs.forEach((channelID) => {
      if (data[channelID]["videos"][item.id]) {
        let views = parseInt(item.statistics.viewCount);
        data[channelID]["videos"][item.id].views = views + 1;
        data[channelID].totalViewCount += views + 1;
        console.log(
          views,
          data[channelID].maxViewCount,
          data[channelID].minViewCount
        );
        if (views > data[channelID].maxViewCount) {
          data[channelID].maxViewCount = views + 2;
        }

        if (views < data[channelID].minViewCount) {
          data[channelID].minViewCount = views;
        }

        return;
      }
    });
  });

  ChannelIDs.forEach((channelID) => {
    data[channelID].avgViewCount = Math.floor(
      data[channelID].totalViewCount /
        Object.keys(data[channelID].videos).length
    );
  });
}

export async function FetchData(API_KEY, _channelIDs = [], setError) {
  const data = {};
  let channelIDs = [..._channelIDs].filter(Boolean);
  console.log(channelIDs);

  try {
    let PromiseIds = channelIDs.map(
      async (id) => await getVidIdsByChannelID(API_KEY, id, data)
    );
    let ids = await (await Promise.all(PromiseIds))?.flat();
    console.log(ids);

    let chunkIDS = chunkArray(ids, 40);

    let vidpromises = chunkIDS.map(async (chunk) => {
      await updateViewsForVideos(API_KEY, chunk, data);
    });

    await Promise.all(vidpromises);
  } catch (err) {
    setError(err.message);
    return;
  }
  let resultEvents = [];

  let allDayMessages = {};

  channelIDs.forEach((id) => {
    let channelData = data[id];
    let channelvids = channelData["videos"];

    Object.values(channelvids).forEach((vid) => {
      let ogDate = new Date(vid.publishedAt);

      let AllDayIndex = ogDate.getDay();

      if (!allDayMessages[AllDayIndex]) {
        allDayMessages[AllDayIndex] = [];
      }

      console.log(
        `${vid.views} - ${channelData.minViewCount} = ${
          vid.views - channelData.minViewCount
        }`
      );

      let newScore = Math.round(
        ((vid.views - channelData.minViewCount) /
          (channelData.maxViewCount - channelData.minViewCount)) *
          10
      );

      // let score = vid.views / channelData.avgViewCount;

      allDayMessages[AllDayIndex].push(newScore);
      //   console.log({ ogDate });

      let start = moment()
        .startOf("week")
        .add(ogDate.getDay(), "days")
        .add(ogDate.getHours(), "hours")
        .add(ogDate.getMinutes, "minutes")
        .toDate();
      let end = moment(start).add(30, "minutes").toDate();

      resultEvents.push({
        title: newScore + " | " + vid.title,
        start: start,
        end: end,
        score: newScore,
      });
    });
  });

  Object.keys(allDayMessages).forEach((day) => {
    let vidsArray = allDayMessages[day];

    let vidsCount = vidsArray.length;
    let totalScore = 0;
    vidsArray.forEach((v) => {
      totalScore += v;
    });
    totalScore /= vidsCount;
    resultEvents.push({
      title: `vids: ${vidsCount} | avg score: ${totalScore}`,
      start: moment().startOf("week").add(day, "days").toDate(),
      end: moment().startOf("week").add(day, "days").add(5, "hours").toDate(),
      allDay: true,
      score: totalScore,
    });
  });

  console.log("complete", resultEvents);
  return resultEvents;
}

/**
 * Event {
 *  title: string,
 *  start: Date,
 *  end: Date,
 *  allDay?: boolean
 *  resource?: any,
 * }
 */

function chunkArray(myArray, chunk_size) {
  var results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }

  return results;
}
